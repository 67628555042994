import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { Ability, PureAbility } from '@casl/ability';
// import { AvService } from './services/av.service';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { NzMessageServiceModule } from 'ng-zorro-antd/message';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { appConfig } from '../config/app.config';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LogInterceptor } from './interceptors/log.interceptor';
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from './interceptors/timeout.interceptor';

// import { NgxPermissionsModule } from 'ngx-permissions';

// import { EntityDataModule } from '@ngrx/data';
// import { EffectsModule } from '@ngrx/effects';
// import { StoreModule } from '@ngrx/store';
// import { entityConfig } from './entity-metadata';

const ngZorroConfig: NzConfig = {
  // 注意组件名称没有 nz 前缀
  message: { nzDuration: 1000 },
  // notification: { nzTop: 240 },
};

@NgModule({
  declarations: [],
  imports: [
    //
    HttpClientModule,

    NzNotificationModule,
    NzMessageServiceModule,

    // StoreModule.forRoot({}),
    // EffectsModule.forRoot([]),
    // EntityDataModule.forRoot(entityConfig),
    // NgxPermissionsModule.forRoot(),
  ],
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    // avSer: AvService,
    // ngfConfig: NgForageConfig
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
    // avSer.init();
    // avSer.initLCDB().subscribe();
    // console.log('Routes: ', router.config);

    // ngfConfig.configure({
    //   name: 'fnw-stream',
    //   driver: [Driver.LOCAL_STORAGE],
    // });
  }

  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: CoreModule,
      providers: [
        //
        { provide: NZ_CONFIG, useValue: ngZorroConfig },
        { provide: Ability, useValue: new Ability() },
        { provide: PureAbility, useExisting: Ability },

        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
        { provide: DEFAULT_TIMEOUT, useValue: appConfig.httpTimeout },
      ],
    };
  }
}
