import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Injectable()
export class LogInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const started = Date.now();
    let status: string,
      resBody: any,
      msg = `${req.method} ${req.urlWithParams}`;
      // req = req.clone({
      //   withCredentials: true,
      // })

    console.log('111',msg, req.body);
    // console.log(req);

    return next.handle(req).pipe(
      tap(
        (event) => {
          status = event instanceof HttpResponse ? 'succeeded' : '';
          if (event instanceof HttpResponse) resBody = event.body;
        },
        (error) => {
          status = 'failed';
          console.error(error);
        },
      ),
      finalize(() => {
        const elapsed = Date.now() - started;
        const msg = `${status} ${req.method} ${req.urlWithParams} in ${elapsed} ms.`;
        console.log(msg);
        if (status == 'succeeded') console.log('resBody:', resBody);
      }),
    );
  }
}
