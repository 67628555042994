class AppConfig {
  /** 当前域名 */
  baseUrl = (window.location.href.match(/^http[^#?]+\//i) || [])[0] || '';

  /** 接口域名 */
  epHost = (() => {
    let host = 'https://pdsixd.porsche-cloud.com';
    if (/(test.fnws)/.test(this.baseUrl)) host = 'https://test.thefront.com.cn/porsche.pds';
    if (/(localhost|10.0.0)/.test(this.baseUrl)) host = 'https://test.thefront.com.cn/porsche.pds';
    return host;
  })();

  /** 私钥 */
  PrivateKey = '123123';

  /** 接口地址 */
  ep = {
    /** 评论列表 */
    testList: `${this.epHost}/admin/comment/list`,
    /** 审核拒绝 */
    refuse: `${this.epHost}/admin/comment/refuse`,
    /** 审核通过 */
    approved: `${this.epHost}/admin/comment/approved`,
    /** 敏感词显示 */
    desc: `${this.epHost}/admin/sensitivewords/desc`,
    /** 敏感词添加 */
    sensitivewordsAdd: `${this.epHost}/admin/sensitivewords/add`,

    time: `${this.epHost}/client/time`,

    /** 登录 */
    login: `${this.epHost}/admin/login`,
    /**订单列表 */
    orders: `${this.epHost}/admin/orders`,
    orders2: `${this.epHost}/classic/admin/order/list`,

    /** 日志 */
    journal: `${this.epHost}/admin/logs`,

    /** 修改密码 */
    changPas: `${this.epHost}/admin/users`,
    /**修改经销商密码 */
    changPas2: `${this.epHost}/admin/users_reset`,
    /**修改经销商邮箱 */
    changPas3: `${this.epHost}/admin/reset_dealer_email`,

    /**订单信息修改购买状态 */
    buy: `${this.epHost}/admin/orders_buy`,
    buy2: `${this.epHost}/classic/admin/order/buy_status`,
    /**订单信息修改跟进状态 */
    follow: `${this.epHost}/admin/orders_follow`,
    follow2: `${this.epHost}/classic/admin/order/follow_status`,
    /**订单信息修改备注 */
    remark: `${this.epHost}/admin/orders_remark`,
    remark2: `${this.epHost}/classic/admin/order/remark`,
    /**导出 */
    export: `${this.epHost}/admin/orders_export`,
    export2: `${this.epHost}/classic/admin/order/export`,
    getEmail: `${this.epHost}/admin/dealers_email`,
    putDealers: `${this.epHost}/admin/dealers`,
    dealersAll: `${this.epHost}/admin/dealers_all`,
    loginOut: `${this.epHost}/admin/login_out`,

    /**忘记密码发送邮件 */
    emailUrl: `${this.epHost}/admin/email_url`,
    emailCode: `${this.epHost}/admin/email_code`,
    forgotPwd: `${this.epHost}/admin/forgot_pwd`,

    /**导出用户优惠券信息 */
    couponsExport: `${this.epHost}/admin/coupons_export`,
    /**核销优惠券 */
    couponUse: `${this.epHost}/admin/coupon_use`,
    /**生成专属优惠卷 */
    couponCreate: `${this.epHost}/admin/coupon_create`,
    /**  用户优惠卷列表*/
    couponsAll: `${this.epHost}/admin/coupons_all`,
    /** 修改经销商手机号 */
    dealersPhone: `${this.epHost}/admin/dealers_phone`,
    /** 设置优惠券有效期 */
    dealersCouponTime: `${this.epHost}/admin/dealers_coupon_time`,

    /**获取图形验证码 */
    getGraphocCode: `${this.epHost}/admin/get_graphic_code`,

    getListTEQ: `${this.epHost}/teq/admin/order/list`,
    teqExport: `${this.epHost}/teq/admin/order/export`,
    followTEQ: `${this.epHost}/teq/admin/order/followStatus`,
    remarkTEQ: `${this.epHost}/teq/admin/order/remark`,
    boughtTEQ: `${this.epHost}/teq/admin/order/buyStatus`,

    getListExplore: `${this.epHost}/explore_loyalty/admin/order/list`,
    ExploreExport: `${this.epHost}/explore_loyalty/admin/order/export`,
    followExplore: `${this.epHost}/explore_loyalty/admin/order/followStatus`,
    remarkExplore: `${this.epHost}/explore_loyalty/admin/order/remark`,
    boughtExplore: `${this.epHost}/explore_loyalty/admin/order/buyStatus`,

    remark11: `${this.epHost}/double_eleven_active/admin/order/consume`,
    addres11: `${this.epHost}/double_eleven_active/admin/order/mailinfo`,
    export11: `${this.epHost}/double_eleven_active/2022/admin/order/export`,

    getOrderList: `${this.epHost}/teq_exhaust/admin/order/list`,
    putOrderExhaust: `${this.epHost}/teq_exhaust/admin/order`,
    exportOrder: `${this.epHost}/teq_exhaust/admin/order/export`,

    gete1g1List: `${this.epHost}/e1g1/admin/order/list`,
    E1G1Order: `${this.epHost}/e1g1/admin/order`,
    exportE1G1Order: `${this.epHost}/e1g1/admin/order/export`,

    dealerRegister: `${this.epHost}/double_eleven_active/2022/admin/dealer/register`,
    dealerAdminList: `${this.epHost}/double_eleven_active/2022/admin/dealer/list`,
    exportDealer: `${this.epHost}/double_eleven_active/2022/admin/dealer/export`,
    history: `${this.epHost}/double_eleven_active/2022/admin/dealer/register/history/list`,
    orders11: `${this.epHost}/double_eleven_active/2022/admin/order/list`,
    putOrderWrite: `${this.epHost}/double_eleven_active/2022/admin/order/write_off`,
    postGivePrize: `${this.epHost}/double_eleven_active/2022/admin/order/give_prize`,
    putCourier: `${this.epHost}/double_eleven_active/2022/admin/order/courier`,
    courierHistory: `${this.epHost}/double_eleven_active/2022/admin/order/courier/history`,
    orderExcelUrl: `${this.epHost}/double_eleven_active/2022/admin/order/import_courier`,

    // 2023年活动接口 !! 改成2024年活动接口
    dealerRegister23: `${this.epHost}/double_eleven_active/2024/admin/dealer/register`,
    dealerAdminList23: `${this.epHost}/double_eleven_active/2024/admin/dealer/list`,
    exportDealer23: `${this.epHost}/double_eleven_active/2024/admin/dealer/export`,
    history23: `${this.epHost}/double_eleven_active/2024/admin/dealer/register/history/list`,
    orders1123: `${this.epHost}/double_eleven_active/2024/admin/order/list`,
    putOrderWrite23: `${this.epHost}/double_eleven_active/2024/admin/order/write_off`,
    postGivePrize23: `${this.epHost}/double_eleven_active/2024/admin/order/give_prize`,
    putCourier23: `${this.epHost}/double_eleven_active/2024/admin/order/courier`,
    courierHistory23: `${this.epHost}/double_eleven_active/2024/admin/order/courier/history`,
    orderExcelUrl23: `${this.epHost}/double_eleven_active/2024/admin/order/import_courier`,
    export1123: `${this.epHost}/double_eleven_active/2024/admin/order/export`,
    get2024UserInfo: `${this.epHost}/double_eleven_active/2024/admin/order/user_info`,
    get2024ExportNumber: `${this.epHost}/double_eleven_active/2024/admin/order/export/number`,

    dealersAllList: `${this.epHost}/admin/dealers/all_list`,

    postOrderList: `${this.epHost}/dream_watch_2023/admin/order/list/info`,
    postOrderInfo: `${this.epHost}/dream_watch_2023/admin/order/info`,
    export23: `${this.epHost}/dream_watch_2023/admin/order/info/export`,
    orderExcelUrl2: `${this.epHost}/dream_watch_2023/admin/order/info/import`,



    // 2023太阳镜
    postOrderList2023: `${this.epHost}/sunglasses2023/admin/order/list/info`,
    exportSunglasses: `${this.epHost}/sunglasses2023/admin/order/info/export`,
    postOrderInfo23: `${this.epHost}/sunglasses2023/admin/order/info`,
    sunglasses2023: `${this.epHost}/sunglasses2023/admin/order/info/import`,

    // 2024腕表
    postOrderList2024: `${this.epHost}/garmin_epix_pro2024/admin/order/list/info`,
    export24: `${this.epHost}/garmin_epix_pro2024/admin/order/info/export`,
    orderExcelUrl2024: `${this.epHost}/garmin_epix_pro2024/admin/order/info/import`,
    orderInfo2024: `${this.epHost}/garmin_epix_pro2024/admin/order/info`,

    dealerRegister24: `${this.epHost}/garmin_epix_pro2024/admin/dealer/register`,


  };

  /** http 请求超时时长 */
  httpTimeout = 30000;
}

/** 单例对象函数 */
const getInstance = (() => {
  let s: AppConfig;
  return () => {
    if (!s) s = new AppConfig();
    return s;
  };
})();
export const appConfig = getInstance();
