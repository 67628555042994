import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authToken = {};
    const authReq = req.clone({
      headers: req.headers
        //
        // .delete('Access-Control-Allow-Headers')
        // .set('Content-Type', 'text/plain'),yarn
    });

    return next.handle(authReq);
  }
}
